var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "mb-n6 py-0",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "psi-form-element",
                {
                  staticClass: "mb-1",
                  attrs: { label: "Property", required: "" }
                },
                [
                  _c("v-select", {
                    staticClass: "mt-2",
                    attrs: {
                      "prepend-inner-icon": "mdi-city",
                      placeholder:
                        _vm.properties.length > 0 ? "Select property" : "",
                      solo: "",
                      items: _vm.properties,
                      "item-text": "name",
                      "item-value": "id",
                      required: "",
                      "persistent-hint": true,
                      hint: "Property is required for upload",
                      disabled: _vm.properties.length == 0 || _vm.uploaded
                    },
                    model: {
                      value: _vm.property,
                      callback: function($$v) {
                        _vm.property = $$v
                      },
                      expression: "property"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "psi-form-element",
                {
                  staticClass: "mb-1",
                  attrs: { label: "Source", required: "" }
                },
                [
                  _c("v-select", {
                    staticClass: "mt-2",
                    attrs: {
                      required: "",
                      "prepend-inner-icon": "mdi-form-select",
                      solo: "",
                      items: _vm.sources,
                      disabled: _vm.uploaded
                    },
                    model: {
                      value: _vm.source,
                      callback: function($$v) {
                        _vm.source = $$v
                      },
                      expression: "source"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "psi-form-element",
                {
                  staticClass: "mb-1",
                  attrs: { label: "Applicants", required: "" }
                },
                [
                  _c("v-select", {
                    staticClass: "mt-2",
                    attrs: {
                      "prepend-inner-icon": "mdi-account",
                      solo: "",
                      required: "",
                      items: _vm.applicants,
                      disabled: _vm.uploaded
                    },
                    model: {
                      value: _vm.applicantCount,
                      callback: function($$v) {
                        _vm.applicantCount = $$v
                      },
                      expression: "applicantCount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "psi-form-element",
                { staticClass: "mb-1", attrs: { label: "File", required: "" } },
                [
                  _c("psi-form-file-upload", {
                    attrs: {
                      required: "",
                      disabled: _vm.fileUploadDisabled,
                      clearable: false,
                      file: _vm.file,
                      action: "/api/upload",
                      csrf_token: _vm.csrf_token,
                      hint: _vm.$_.get(_vm.file, "hint", ""),
                      applicant_id: _vm.$uuid.v4()
                    },
                    on: { update: _vm.update }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }