<template>
    <v-form ref="form" v-model="valid" lazy-validation class="mb-n6 py-0">
        <v-row fluid>
            <v-col>
                <psi-form-element label="Property" class="mb-1" required>
                    <v-select
                        prepend-inner-icon="mdi-city"
                        :placeholder="properties.length > 0 ? 'Select property': ''"
                        v-model="property"
                        class="mt-2"
                        solo
                        :items="properties"
                        item-text="name"
                        item-value="id"
                        required
                        :persistent-hint="true"
                        hint="Property is required for upload"
                        :disabled="properties.length == 0 || uploaded"
                    ></v-select>
                </psi-form-element>
            </v-col>
            <v-col>
                <psi-form-element label="Source" class="mb-1" required>
                    <v-select
                        required
                        prepend-inner-icon="mdi-form-select"
                        class="mt-2"
                        solo
                        :items="sources"
                        v-model="source"
                        :disabled="uploaded"
                    ></v-select>
                </psi-form-element>
            </v-col>
            <v-col cols="2">
                <psi-form-element label="Applicants" class="mb-1" required>
                    <v-select
                        prepend-inner-icon="mdi-account"
                        class="mt-2"
                        solo
                        required
                        :items="applicants"
                        v-model="applicantCount"
                        :disabled="uploaded"
                    ></v-select>
                </psi-form-element>
            </v-col>
            <v-col cols="4">
                <psi-form-element label="File" class="mb-1" required>
                    <psi-form-file-upload
                        required
                        :disabled="fileUploadDisabled"
                        :clearable="false"
                        :file="file"
                        action="/api/upload"
                        :csrf_token="csrf_token"
                        :hint="$_.get(file,'hint','')"
                        :applicant_id="$uuid.v4()"
                        @update="update"
                    ></psi-form-file-upload>
                </psi-form-element>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
export default {
    name: "upload-file",
    components: {},
    props: {
        properties: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            valid: true,
            uploaded: false,
            property: null,
            csrf_token:
                this.$app.csrfToken === undefined ? "" : this.$app.csrfToken,
            file: {
                filename: "application",
                file: null,
                hint: "Must be in PDF format",
                id: this.$uuid.v4(),
                label: "Application File",
                name: "application",
                status: "",
                required: true,
            },
            source: "Appfolio",
            sources: ["Appfolio"],
            applicants: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            applicantCount: 1,
        };
    },
    computed: {
        fileUploadDisabled() {
            return (
                this.applicantCount === null ||
                this.applicantCount === undefined ||
                this.source === null ||
                this.source === undefined ||
                this.property === null ||
                this.uploaded
            );
        },
    },
    methods: {
        update(data) {
            this.file["file"] = data;
            this.status = "success";
            this.file["meta"] = {
                source: this.source,
                applicantCount: this.applicantCount,
                property: this.property,
            };
            this.uploaded = true;
            this.$emit("fileUploaded", this.file);
        },
    },
};
</script>

<style scoped>
</style>