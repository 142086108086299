var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-0", attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "primary" },
        [
          _c("v-icon", { staticClass: "white--text mr-2" }, [
            _vm._v("mdi-file-upload")
          ]),
          _c("h4", { staticClass: "white--text font-weight-regular" }, [
            _vm._v("Upload applications for " + _vm._s(_vm.company.name))
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "white" },
              on: { click: _vm.closeDialog }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticStyle: { height: "780px" } },
        [
          _vm._l(_vm.fileblocks, function(ref) {
            var company = ref.company
            var filesets = ref.filesets
            return _c(
              "div",
              { key: company.id },
              [
                _c(
                  "v-row",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "primary", small: "" }
                          },
                          [_vm._v("Files: " + _vm._s(_vm.files))]
                        ),
                        _c(
                          "v-chip",
                          { attrs: { color: "success darken-2", small: "" } },
                          [_vm._v("Applicants: " + _vm._s(_vm.applicants))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-divider"),
                _vm._l(filesets, function(fileset) {
                  return _c("upload-file", {
                    key: fileset.id,
                    attrs: {
                      properties: _vm.$_.get(fileset, "properties", [])
                    },
                    on: {
                      fileUploaded: function($event) {
                        return _vm.fileUploaded(fileset.id, $event)
                      }
                    }
                  })
                })
              ],
              2
            )
          }),
          _c(
            "v-row",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.disableAddMore },
                      on: { click: _vm.createFileSet }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-file-plus")
                      ]),
                      _vm._v("Add More Files ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-8",
                      attrs: { color: "primary", disabled: _vm.disableDone },
                      on: { click: _vm.closeDialog }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-check")
                      ]),
                      _vm._v("Done ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }