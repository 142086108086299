<template>
    <v-card class="mt-0" flat>
        <v-card-title class="primary">
            <v-icon class="white--text mr-2">mdi-file-upload</v-icon>
            <h4 class="white--text font-weight-regular">Upload applications for {{company.name}}</h4>
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="closeDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text style="height:780px;">
            <div v-for="({company,filesets}) in fileblocks" :key="company.id">
                <v-row fluid>
                    <v-col class="text-right">
                        <v-chip color="primary" small class="mr-2">Files: {{files}}</v-chip>
                        <v-chip color="success darken-2" small>Applicants: {{applicants}}</v-chip>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <upload-file
                    v-for="fileset in filesets"
                    :properties="$_.get(fileset,'properties',[])"
                    :key="fileset.id"
                    @fileUploaded="fileUploaded(fileset.id, $event)"
                ></upload-file>
            </div>
            <v-row fluid>
                <v-col cols="12" class="text-right">
                    <v-btn color="primary" @click="createFileSet" :disabled="disableAddMore">
                        <v-icon class="mr-2">mdi-file-plus</v-icon>Add More Files
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="closeDialog"
                        class="ml-8"
                        :disabled="disableDone"
                    >
                        <v-icon class="mr-2">mdi-check</v-icon>Done
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import UploadFile from "./ApplicationUploadsUploadFile";

export default {
    name: "application-uploads-upload",
    components: { UploadFile },
    props: {
        company: {
            required: false,
            default: null,
            validator: (prop) => typeof prop === "object" || prop === null,
        },
    },
    data() {
        return {
            fileblocks: [],
            snackbar: false,
            message: "",
            files: 0,
            applicants: 0,
        };
    },
    computed: {
        disableDone() {
            return this.files == 0;
        },
        disableAddMore: {
            deep: true,
            immediate: true,
            get() {
                return this.fileblocks[0].filesets.length > this.files;
            },
        },
    },
    watch: {
        company: {
            immediate: true,
            handler(newValue) {
                if (!!newValue && Object.keys(newValue).length > 0) {
                    this.createBlockSet(newValue);
                }
            },
        },
    },
    mounted() {},
    methods: {
        closeDialog() {
            this.createBlockSet(this.company);
            this.files = 0;
            this.applicants = 0;
            this.$emit("close");
        },
        resetFileBlocks() {},
        createBlockSet(company) {
            this.fileblocks = [
                {
                    company: company,
                    filesets: [],
                },
            ];
            // Don't notify on company change
            this.createFileSet();
        },
        createFileSet() {
            if (this.fileblocks.length > 0) {
                let fileblock = this.fileblocks.pop();
                fileblock.filesets.push({
                    id: this.$uuid.v4(),
                    properties: fileblock.company.properties,
                    sources: [],
                });
                this.fileblocks.push(fileblock);
            } else {
                console.error(
                    "Trying to create a files set with no file blocks"
                );
            }
        },
        fileUploaded(id, event) {
            // only supporting one company block at a time we can just get first company in block
            let fileset = this.fileblocks[0].filesets.find((val) => {
                return val.id === id;
            });
            fileset.file = event;
            this.files++;
            this.applicants += event.meta.applicantCount;
            this.$toast({ content: "File successfully uploaded" });
        },
    },
};
</script>

<style scoped>
</style>