import { render, staticRenderFns } from "./ApplicationUploadsUploadFile.vue?vue&type=template&id=4f60a67b&scoped=true&"
import script from "./ApplicationUploadsUploadFile.vue?vue&type=script&lang=js&"
export * from "./ApplicationUploadsUploadFile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f60a67b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VForm,VRow,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f60a67b')) {
      api.createRecord('4f60a67b', component.options)
    } else {
      api.reload('4f60a67b', component.options)
    }
    module.hot.accept("./ApplicationUploadsUploadFile.vue?vue&type=template&id=4f60a67b&scoped=true&", function () {
      api.rerender('4f60a67b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/uploads/components/ApplicationUploadsUploadFile.vue"
export default component.exports